<template>
    <v-app>
        <BreadcrumbComponent :items=items />

        <v-container class="mb-4">

            <v-container v-if="!Object.keys(formattedData).length">
                <v-alert type="success" outlined dense icon="mdi-check-circle" dismissible v-if="success">
                    Les données ont été enregistrées avec succès.
                </v-alert>

                <v-alert type="info" outlined dense>
                    Veuillez télécharger votre fichier Excel conforme à l'exemple fourni. Assurez-vous que :
                    <ul>
                        <li>Le fichier est au format <strong>.xlsx</strong> ou <strong>.xls</strong>.</li>
                        <li>Les colonnes respectent la structure de l'exemple.</li>
                        <li>Aucune colonne ou donnée supplémentaire n'est ajoutée.</li>
                        <li>Le fichier ne dépasse pas 5 Mo.</li>
                    </ul>
                    <p>
                        Téléchargez l'exemple de fichier
                        <a href="/data/import_template.xlsx" target="_blank">ICI</a> .
                    </p>
                </v-alert>

                <v-file-input v-model="file" label="Télécharger le fchier" prepend-icon="mdi-upload" show-size outlined
                    dense :rules="fileRules" @change="handleFileChange"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" dropzone>
                    <template v-slot:append>
                        <v-icon>mdi-drag</v-icon>
                    </template>
                </v-file-input>
            </v-container>

            <PreloaderComponent :enable=enable />

            <v-container v-if="Object.keys(formattedData).length">
                <v-alert type="success" outlined dense icon="mdi-check-circle" dismissible v-if="success">
                    Les données ont été importées avec succès. Vous pouvez les modifier avant de les enregistrer.
                </v-alert>
                <v-alert type="info" outlined dense icon="mdi-information" dismissible>
                    Vous pouvez modifier les données avant de les enregistrer. Double-cliquez sur une cellule pour
                    commencer l'édition.<br/>
                    Le barcode doit est unique pour chaque produit<br/>
                    Le numéro de téléphone est obligatoire pour l'enregistrement de compte client
                </v-alert>

                <v-alert type="danger" outlined dense icon="mdi-information" dismissible v-if="error_occured">
                    {{ error_message || "Une erreur s'est produite lors de l'enregistrement des données." }}
                </v-alert>

                <div class="d-flex justify-end align-center mb-2">
                    <v-btn color="info mr-2" @click="clearData">
                        <v-icon left>mdi-upload</v-icon>
                        Recharger le fichier
                    </v-btn>

                    <v-btn color="danger mr-2" @click="refreshData">
                        <v-icon left>mdi-refresh</v-icon>
                        Rafraîchir
                    </v-btn>

                    <v-btn color="primary" @click="saveData">
                        <v-icon left>mdi-content-save</v-icon>
                        Enregistrer
                    </v-btn>
                </div>

                <v-divider></v-divider>

                <v-container>
                    <v-tabs v-model="tab">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="table in tables" :key="table.name">
                            {{ table.label }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="table in tables" :key="table.name">
                            <v-card flat>

                                <div class="d-flex justify-end align-center mb-2">
                                    <v-btn color="primary" @click="addRow(table.name)">
                                        <v-icon left>mdi-plus</v-icon>
                                        Ajouter
                                    </v-btn>
                                </div>

                                <v-data-table :headers="table.headers" :items="formattedData[table.name] || []"
                                    class="elevation-1">

                                    <template v-slot:item="{ item, index }">
                                        <tr>
                                            <td class="pa-2"
                                                v-for="(header, colIndex) in table.headers.filter(header => header.value !== 'actions')"
                                                :key="colIndex" @dblclick="editCell(index, header.value, table.name)">

                                                <template v-if="isEditing(index, header.value, table.name)">
                                                    <v-select
                                                        v-if="table.name === 'products' && header.value === 'category'"
                                                        v-model="editableValue" label="Categorie" :items="categories"
                                                        @change="saveEdit(index, header.value, table.name)" autofocus
                                                        dense solo hide-details></v-select>


                                                    <v-text-field v-else v-model="editableValue" type="header.type"
                                                        :step="header.type === 'number' ? 0.01 : null"
                                                        :suffix="header.suffix"
                                                        @blur="saveEdit(index, header.value, table.name)"
                                                        @keyup.enter="saveEdit(index, header.value, table.name)"
                                                        autofocus dense solo hide-details />
                                                </template>

                                                <template v-else>
                                                    {{ item[header.value] }}
                                                </template>
                                            </td>

                                            <td class="pa-2 text-right">
                                                <v-btn icon color="red" @click="deleteRow(index, table.name)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-container>

            </v-container>

        </v-container>
    </v-app>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
    name: 'ImportView',
    methods: {
        handleFileChange(newFile) {
            const dataKeys = ['categories', 'products', 'customers'];
            const sheetNames = ['Categories', 'Produits', 'Clients'];

            const schemas = {
                Produits: {
                    'Nom': { prop: 'name', type: String, required: true },
                    'Categorie': { prop: 'category', type: String, required: true },
                    'Cout': { prop: 'cost', type: Number, required: true },
                    'Prix': { prop: 'price', type: Number, required: true },
                    'Prix minimum': { prop: 'min_price', type: Number, required: false },
                    'Stock initial': { prop: 'stock', type: Number, required: true },
                    'Code barre': { prop: 'barcode', type: String, required: true },
                },
                Categories: {
                    'Nom': { prop: 'name', type: String, required: true },
                },
                Clients: {
                    'Nom': { prop: 'full_name', type: String, required: true },
                    'Email': { prop: 'email', type: String, required: false },
                    'Telephone': { prop: 'phone', type: String, required: false },
                    'Adresse': { prop: 'address', type: String, required: false },
                    'Reduction': { prop: 'discount', type: Number, required: false },
                },
            }

            readXlsxFile(newFile, { getSheets: true }).then((sheets) => {

                this.enable = false;

                if (sheets.length !== sheetNames.length) {
                    this.enable = true;
                    alert('Le fichier Excel ne contient pas le bon nombre de feuilles.');
                    return;
                }

                const data = {};
                let sheetsProcessed = 0;

                for (const sheet of sheets) {
                    const index = sheetNames.indexOf(sheet.name);

                    readXlsxFile(newFile, { schema: schemas[sheet.name], sheet: index + 1 }).then(({ rows, errors }) => {
                        if (errors.length) {
                            this.enable = true;
                            alert(`Erreur lors de la lecture de la feuille ${sheet.name}. Veuillez vérifier les données.`);
                            return;
                        }

                        data[dataKeys[index]] = rows;
                        sheetsProcessed++;

                        if (sheetsProcessed === sheetNames.length) {
                            this.excelData = this.freshData(data);
                            this.formattedData = this.freshData(data);
                        }
                    });
                }

                this.enable = true;
            });
        },

        clearData() {
            this.formattedData = {};
            this.excelData = {};
            this.file = null;
        },

        refreshData() {
            this.formattedData = this.freshData(this.excelData);
        },

        saveData() {
            this.enable = false;
            this.success = false;
            this.error_occured = false;

            const url = '/data/import';

            this.$axios.post(url, this.getJsonData())
                .then(response => {
                    try {
                        if (response && response.status >= 200 && response.status < 300) {
                            this.clearData();
                            this.success = true;
                        } else {
                            this.error_occured = true;
                            this.error_message = response?.data?.message || "Une erreur s'est produite.";
                        }
                    } catch (error) {
                        this.error_occured = true;
                        this.error_message = "Erreur lors du traitement de la réponse.";
                        console.error("Erreur dans then():", error);
                    } finally {
                        this.enable = true;
                    }

                    console.log(response);
                    console.log(this.getJsonData());
                })
                .catch(error => {
                    this.error_occured = true;
                    this.error_message = error.response?.data?.message || "Une erreur de connexion s'est produite.";
                    console.error("Erreur Axios:", error, error.response, error.response.data);
                    this.enable = true;
                });
        },


        editCell(row, column, table) {
            this.editingCell = { row, column, table };
            this.editableValue = this.formattedData[table][row][column];
            this.lastValueOnEdit = this.formattedData[table][row][column];
        },

        saveEdit(row, column, table) {
            this.formattedData[table][row][column] = this.editableValue.trim();
            if (table === 'categories') {
                for (const product of this.formattedData.products) {
                    if (product.category === this.lastValueOnEdit) {
                        product.category = this.formattedData[table][row].name;
                    }
                }
            }
            this.stopEditing();
        },

        stopEditing() {
            this.editingCell = { row: null, column: null, table: null };
        },

        isEditing(row, column, table) {
            return this.editingCell.row === row &&
                this.editingCell.column === column &&
                this.editingCell.table === table;
        },

        addRow(table) {
            const newRow = {};
            this.tables.find(t => t.name === table).headers.forEach(header => {
                if (header.value !== 'actions') {
                    newRow[header.value] = header.type === 'number' ? 0 : '';
                }
            });
            this.formattedData[table] = this.formattedData[table] || [];
            this.formattedData[table].unshift(newRow);
        },

        deleteRow(index, table) {
            const value = this.formattedData[table][index].name;
            if (table === 'categories') {
                for (const product of this.formattedData.products) {
                    if (product.category === value) {
                        product.category = '';
                    }
                }
            }
            this.formattedData[table].splice(index, 1);
        },

        getJsonData() {
            return JSON.stringify(this.formattedData);
        },

        freshData(data) {
            return JSON.parse(JSON.stringify(data));
        }
    },
    computed: {
        categories() {
            return this.formattedData.categories.map(category => category.name);
        },
    },
    data: () => ({
        tab: null,
        excelData: {},
        formattedData: {},
        file: null,
        enable: true,
        success: false,
        error_occured: false,
        error_message: "",
        editingCell: { row: null, column: null, table: null },
        editableValue: "",
        lastValueOnEdit: "",
        fileRules: [
            (value) => !!value || "Veuillez ajouter votre fichier Excel",
        ],
        tables: [
            {
                name: 'categories',
                label: 'Categories',
                headers: [
                    { text: 'Nom', value: 'name' },
                    { text: 'Actions', value: 'actions', align: 'end' },
                ],
            },
            {
                name: 'products',
                label: 'Produits',
                headers: [
                    { text: 'Nom', value: 'name', type: 'text' },
                    { text: 'Categorie', value: 'category', type: 'text' },
                    { text: 'Cout', value: 'cost', type: 'number' },
                    { text: 'Prix', value: 'price', type: 'number' },
                    { text: 'Prix minimum', value: 'min_price', type: 'number' },
                    { text: 'Stock initial', value: 'stock', type: 'number' },
                    { text: 'Code barre', value: 'barcode', type: 'text' },
                    { text: 'Actions', value: 'actions', align: 'end' },
                ],
            },
            {
                name: 'customers',
                label: 'Clients',
                headers: [
                    { text: 'Nom', value: 'full_name', type: 'text' },
                    { text: 'Email', value: 'email', type: 'email' },
                    { text: 'Telephone', value: 'phone', type: 'text' },
                    { text: 'Adresse', value: 'address', type: 'text' },
                    { text: 'Reduction', value: 'discount', type: 'number', suffix: '%' },
                    { text: 'Actions', value: 'actions', align: 'end' },
                ],
            },
        ],
        items: [
            {
                text: 'Import',
                disabled: false,
                href: '/import',
            }
        ],
    }),
    components: {
        BreadcrumbComponent,
        PreloaderComponent
    },
}
</script>
